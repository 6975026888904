.navbar__main {
    height: 65px;
    width : 100vw;

    position: fixed;
    top     : 0;
    left    : 0;

    background-color: $theme-white;

    z-index: 999;
}

.navbar__spacer {
    height: 65px;
    width: 100vw;
}

.navbar__wrapper {
    height: fit-content;

    &.--active {
        flex-direction: column;
    }
}

.navbar__decorator {
    height: 5px;
    width : 100%;

    background-color: $theme-primary-cyan;
}

.navbar__logo-wrapper {
    margin-left: 30px;

    img {
        height: 60px;
        width : auto;
    }
}

.navbar__links-container {
    height: 60px;
    width : fit-content;

    list-style: none;

    margin-bottom: 0;
    margin-left: 30px;

    padding: 0;

    background-color: $theme-white;

    @include screen-max(768px) {
        display: none !important;
    }

    &.--active {
        height: fit-content;
        width: 100%;

        display: flex !important;
        flex-direction: column;
        justify-content: center;
        margin-left: 0;
        margin-top: 0;
    }

    li {

        margin: 5px;

        font-size      : 14px;
        text-decoration: none;

        color: $theme-dark-blue;

        cursor: pointer;

    }
}

.navbar__button-container {
    height: 60px;
    width : fit-content;

    position: absolute;
    top     : 0;
    right   : 30px;

    button {
        background: none;
        border    : none;

        display: none;

        i {
            font-size: 30px;
            color    : $theme-tertiary-blue;
        }

        @include screen-max(768px) {
            display: block;
        }
    }
}

.navbar__dropdown-menu {
    position: relative;
}

.navbar__dropdown {
    display: none;

    position: absolute;
    top: calc(100% + 25px);
    left: 0;

    height: fit-content;
    width: fit-content;
    
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;

    background-color: $theme-white;

    border-radius: 5px;
}