// Mixins
@mixin screen-max($max) {
    @media (max-width: $max) {
        @content
    }
};

// Theme
$theme-primary-cyan: #00C2E4;
$theme-secondary-orange: #F39200;
$theme-tertiary-blue: #219EBC;
$theme-dark-blue: #1D3B87;
$theme-light-blue: #CAEFED;
$theme-dark-blue-2: #01172E;

$theme-white : #F2F2F2;
$theme-light-gray: #D3D3D3;
$theme-medium-gray: #B1A7A6;
$theme-dark-gray : #161A1D;
$theme-black : #0B090A;

// Font sizes
$fsc-1   : 12;
$fsc-2   : 16;
$fsc-3   : 20;
$fsc-4   : 24;
$fsc-5   : 30;
$fsc-6   : 34;
$fsc-7   : 38;
$fsc-8   : 42;
$fsc-9   : 46;
$fsc-10  : 50;
$fsc-hero: 56;
$fsc-11: 80;
$fsc-hero-2: 100;

:export {
    themeWhite: $theme-white;
    themeSecondaryOrange: $theme-secondary-orange;
    themeMediumGray: $theme-medium-gray;
}