.contactSection__main-div {
    height: fit-content;
    width: 100%;

    background-color: $theme-tertiary-blue;
}

.contactSection__form-wrapper {
    
    h2 {
        margin-top: 30px;
    }
}

.contactSection__form {

    width: 100%;
    
    margin-top: 20px;
    margin-bottom: 70px;

    .form-control {
        width: 100%;

        border-radius: 10px;
        background-color: transparent;
        
        border: 3px solid $theme-white;

        color: $theme-white;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;

        &::placeholder {
            color: darken($color: $theme-white, $amount: 10);
        }

        &::-ms-input-placeholder {
            color: darken($color: $theme-white, $amount: 10);
        }
    }

    .form-group {
        width: 80%;
        max-width: 500px;

        margin-top: 20px;
    }

    textarea {
        height: 150px;
    }

    button {
        width: 70%;
        max-width: 200px;

        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;

        background-color: $theme-white;
        border: none;
        outline: none;

        border-radius: 10px;
        color: $theme-tertiary-blue;
    }
}

.contactSection__error-text {
    min-height: 6px;
}