.reactModal__modal {

    background   : white;
    border-radius: 5px;
    color        : rgb(51, 51, 51);
    display      : inline;
    outline      : none;
    padding      : 0px;
}

.reactModal__modal-overlay {
    background-color: rgba($theme-dark-gray, 0.6);
    bottom          : 0;
    left            : 0;
    right           : 0;
    top             : 0;
    position        : fixed;
    backdrop-filter : blur(5px);

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 998;
}