.standsSection__main {}

.standsSection__container {
    padding-top: 80px;

    @include screen-max (768px) {
        padding-top: 30px;
    }

    .row:nth-child(2) {
        margin-top: 80px;

        @include screen-max (768px) {
            margin-top: 35px;
        }
    }
}



.standsSection__sec-1-img-container {
    position: relative;

    h1 {
        position: absolute;
        top     : 0;
        left    : 3vw;

        z-index: 6;
    }
}

.standsSection__sec-1-img-background {
    position: absolute;
    top     : 0;
    left    : 5vw;

    z-index: 1;

    height: 90%;
    width : 60%;

    background-color: $theme-light-blue;
}

.standsSection__sec-1-img-spacer {
    width : 100%;
    height: 200px;

    @include screen-max(1201px) {
        height: 100px;
    }

    @include screen-max(992px) {
        height: 50px;
    }
}

.standsSection__sec-1-img-wrapper {
    position: relative;
    width   : 100%;

    &::after {
        content: "";
        display: block;

        position: absolute;
        top     : 0;
        left    : 5%;

        height: 90%;
        width : 50%;

        background-color: $theme-light-blue;

        z-index: -1;

        -webkit-box-shadow: 5px 5px 29px -10px rgba(168, 168, 168, 1);
        -moz-box-shadow   : 5px 5px 29px -10px rgba(168, 168, 168, 1);
        box-shadow        : 5px 5px 29px -10px rgba(168, 168, 168, 1);


    }

    img {
        padding-top: 250px;

        position: relative;

        height: auto;
        width : 100%;

        z-index: 3;
        width  : 80%;

        @include screen-max(992px) {
            padding-top: 100px;
        }
    }
}

.standsSection__sec-1-info-container {
    img {
        height: auto;
        width : 100%;
    }

    h2 {
        margin-top: 30px;
    }

    img {
        margin-top: 30px;

        -webkit-box-shadow: 5px 5px 29px -10px rgba(168, 168, 168, 1);
        -moz-box-shadow   : 5px 5px 29px -10px rgba(168, 168, 168, 1);
        box-shadow        : 5px 5px 29px -10px rgba(168, 168, 168, 1);

    }

    p {
        margin-top  : 30px;
        padding-left: 15px;

        position: relative;

        &::after {
            content         : "";
            display         : block;
            position        : absolute;
            top             : 0;
            left            : 0;
            width           : 5px;
            height          : 60px;
            background-color: $theme-primary-cyan;
        }
    }
}

.standsSection__sec-2-title-img-container {

    img {
        height: auto;
        width : 100%;
    }
}

.standsSection__sec-2-title-container {
    position: relative;

    @include screen-max (768px) {
        margin-top: 30px;
        height: 120px;
    }

    h1 {
        position: relative;

        padding-left: 50px;

        &::after {
            content : "";
            position: absolute;
            display : block;
            top     : 0;
            left    : 0;
            height  : 100%;
            width   : 100%;

            background-color  : $theme-light-blue;
            -webkit-box-shadow: 5px 5px 29px -10px rgba(168, 168, 168, 1);
            -moz-box-shadow   : 5px 5px 29px -10px rgba(168, 168, 168, 1);
            box-shadow        : 5px 5px 29px -10px rgba(168, 168, 168, 1);

            z-index: -1;
        }
    }
}

.standsSection__sec-2-title-separator {
    margin-left : 3%;
    margin-right: 1.5%;

    height: 100%;
    width : 2%;

    background-color: $theme-secondary-orange;

    @include screen-max (768px) {
        margin-left: 0%;
    }
}

/* .standsSection__sec-2-title-background {
    margin-left: 1.5%;
    width      : 92%;
    height     : 100%;

    background-color: $theme-light-blue;
} */

.standsSection__sec-2-title {
    position: absolute;
    top     : 0;
    left    : 0;

    width : 100%;
    height: 100%;

    h1 {
        padding-left: 25%;
    }
}

.standsSection__sec-2-decorator-container {
    position: relative;
}

.standsSection__sec-2-decorator-wrapper {
    position: absolute;
    top     : 0;
    left    : 0;

    margin-top: 20px;

    @include screen-max(992px) {
        display: none;
    }

    .cover {
        position: absolute;
        top     : 0;
        left    : 0;

        width : 100%;
        height: 100%;

        padding: inherit;

        background-color: rgba($theme-white, 0.7);
    }

    img {

        height: auto;
        width : 100%;

        -webkit-box-shadow: 5px 5px 29px -10px rgba(168, 168, 168, 1);
        -moz-box-shadow   : 5px 5px 29px -10px rgba(168, 168, 168, 1);
        box-shadow        : 5px 5px 29px -10px rgba(168, 168, 168, 1);
    }
}

.standsSection__info-container {
    position: relative;

    p {
        max-width: 450px;
    }
}

.standsSection__info-img-container {

    img {
        height: auto;
        width : 100%;

        -webkit-box-shadow: 5px 5px 29px -10px rgba(168, 168, 168, 1);
        -moz-box-shadow   : 5px 5px 29px -10px rgba(168, 168, 168, 1);
        box-shadow        : 5px 5px 29px -10px rgba(168, 168, 168, 1);

        &:nth-child(2) {
            margin-top: 25px;
        }
    }
}

.standsSection__info-title {
    position: relative;

    @include screen-max(768px) {
        margin-top: 35px;
    }

    &::after {
        content: "";
        display: block;

        position: absolute;
        top     : 0;
        left    : 0;

        height: 100%;
        width : 5px;

        background-color: $theme-secondary-orange;
    }
}

.standsSection__slider-container {
    width: 100%;
}

.standsSection__slider-wrapper {
    max-width: 1720px;
    width: 82vw;


    @include screen-max(1720px) {
        width: 100%;
    }
}