body {
    margin: 0;
    padding: 0;

    position: relative;
}

// Colors

.color-primary {
    color: $theme-primary-cyan;
}

.color-secondary {
    color: $theme-secondary-orange;
}

.color-tertiary {
    color: $theme-tertiary-blue
}

.color-white {
    color: $theme-white;
}

.color-dark-gray {
    color: $theme-dark-gray;
}

.color-medium-gray {
    color: $theme-medium-gray;
}

.color-light-gray {
    color: $theme-light-gray;
}

// Font size

.base__fsc-1 {
    font-size: #{$fsc-1}+"px";
}

.base__fsc-2 {
    font-size: #{$fsc-2}+"px";
}

.base__fsc-3 {
    font-size: #{$fsc-3}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-3/1.4)}px;
    }
}

.base__fsc-4 {
    font-size: #{$fsc-4}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-4/1.4)}px;
    }
}

.base__fsc-5 {
    font-size: #{$fsc-5}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-5/1.4)}px;
    }
}

.base__fsc-6 {
    font-size: #{$fsc-6}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-6/1.4)}px;
    }
}

.base__fsc-7 {
    font-size: #{$fsc-7}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-7/1.4)}px;
    }
}

.base__fsc-8 {
    font-size: #{$fsc-8}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-8/1.4)}px;
    }
}

.base__fsc-9 {
    font-size: #{$fsc-9}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-9/1.4)}px;
    }
}

.base__fsc-10 {
    font-size: #{$fsc-10}+"px";

    @include screen-max(992px) {
        font-size: #{calc($fsc-10/1.4)}px;
    }
}

.base__fsc-11 {
    font-size: #{$fsc-11}+"px";

    @include screen-max(1500px) {
        font-size: #{calc($fsc-11/1.4)}px;
    }

    @include screen-max (992px) {
        font-size: #{calc($fsc-11/2)}px;
    }
}

.base__fsc-hero {
    font-size: #{$fsc-hero}+"px";

    @include screen-max(1200px) {
        font-size: #{calc($fsc-hero/1.4)}px;
    }

    @include screen-max(992px) {
        font-size: #{calc($fsc-hero/2)}px;
    }
}

.base__fsc-hero-2 {
    font-size: #{$fsc-hero-2}+"px";

    @include screen-max(1200px) {
        font-size: #{calc($fsc-hero-2/1.4)}px;
    }

    @include screen-max(992px) {
        font-size: #{calc($fsc-hero-2/2)}px;
    }

    @include screen-max(576px) {
        font-size: #{calc($fsc-hero-2/3)}px;
    }
}

// Font styles
.base__fs {

    &.--poppins {
        font-family: 'Poppins', sans-serif;
    }

    &.--raleway {
        font-family: 'Raleway', sans-serif;
    }

    &.--color-white {
        color: $theme-white;
    }

    &.--color-primary {
        color: $theme-primary-cyan;
    }

    &.--color-secondary {
        color: $theme-secondary-orange;
    }

    &.--color-tertiary {
        color: $theme-tertiary-blue;
    }

    &.--color-dark-blue {
        color: $theme-dark-blue;
    }

    &.--w-bold {
        font-weight: bold;
    }

    &.--space-y-m {
        line-height: 180%;
    }

    &.--space-y-l {
        line-height: 200%;
    }
}

// Elements
.base__background {
    position: relative;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.--fixed {
        background-attachment: fixed;
    }
}

.base__background-cover {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;

    &.--fixed {
        position: fixed;
    }

    &.--color-primary {
        background-color: $theme-primary-cyan;
        opacity: 0.7;
    }

    &.--color-tertiary-blue {
        background-color: $theme-tertiary-blue;
        opacity: 0.7;
    }

    &.--color-white {
        background-color: $theme-white;
        opacity: 0.7;
    }
}