.slider1__swiper-container {
    height: fit-content;
    width: 100%;

    position: relative;

    margin-top: 30px;
    margin-bottom: 50px;

    @include screen-max(576px) {
        width: 100%;
    }
}

.slider1__swiper-wrapper {
    position: relative;
}

.slider1__swiper-slide {

    background-color: $theme-black;

    border-radius: 10px;

    img {
        height: 200px;
        width: auto;
        max-width: 85%;

        border-radius: 5px;

        cursor: pointer;
    }
}

.slider1__swiper-pagination-prev,
    .slider1__swiper-pagination-next {

        cursor: pointer;

        svg {
            width: 3vw;
            height: auto;

            cursor: pointer;

            margin: 5vw;

            @include screen-max(576px) {
                width: 6vw;
            }
        }

}