.valuesSection__main {
    margin-top: 100px;
}

.valuesSection__img-container {

    position: relative;

    img {
        float: right;
        clear: both;

        position: relative;

        width: 95%;
        height: auto;

        z-index: 2;
    }

    &::after {
        content: "";
        display: block;

        position: absolute;
        top: 30px;
        left: 0;

        background-color: $theme-light-blue;

        height: 100%;
        width: 100%;
    }
}

.valuesSection__info-container {

    @include screen-max (992px) {
        margin-top: 50px;;
    }

    h2 {
        position: relative;

        padding-left: 10px;
        margin-top: 35px;
        margin-left: 10%;

        &::after {
            content: "";
            display: block;

            position: absolute;
            top: 10%;
            left: 0;

            height: 90%;
            width: 5px;

            background-color: $theme-secondary-orange;
        }
    }

    p {
        margin-top: 25px;
        margin-left: 10%;
    }
}

.valuesSection__values {
    position: relative;

    margin-top: 65px;

    @include screen-max(992px) {
        margin-top: 50px;
    }
}

.valuesSection__values-menu-container {

    @include screen-max (768px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .valuesSection__menu-wrapper {

        display: flex;
        flex-direction: column;

        @include screen-max (992px) {
            
            flex-direction: row;

            align-items: center;
            justify-content: center;
        }

        p {

            color: $theme-primary-cyan;
    
            font-size: 26px;
    
            font-family: 'Poppins', sans-serif;
    
            cursor: pointer;

            vertical-align: middle;

            padding-top: 5px;
            padding-left: 15px;

            @include screen-max (768px) {
                margin: 0;
            }
    
            &.active {
                color: $theme-dark-blue;
                font-size: 32px;
                padding-top: 0;
            }
        }
    }
}

.valuesSection__values-info {

    p {

        @include screen-max(1200px) {
            min-height: 250px;
        }

        @include screen-max(992px) {
            padding-left: 20px;
            min-height: fit-content;
            margin-bottom: 50px;
        }
    }

    @include screen-max(768px) {
        margin-top: 35px;

        p {
            text-align: center;
            padding-left: 0;
        }
    }
}