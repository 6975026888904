.mediaViewModal__main {
    height: fit-content;
    width: 45vw;

    @include screen-max(1200px) {
        width: 60vw;
    }

    @include screen-max(992px) {
        width: 80vw;
    }

    img {
        height: auto;
        width: 100%;
        
        border-radius: 0px 0px 5px 5px;
    }
}

.mediaViewModal__controls {
    height: 30px;
    width: 100%;

    background-color: $theme-white;

    border-radius: 5px 5px 0px 0px;

    button {
        float: right;
        clear: both;

        margin-right: 5px;

        border: none;
        outline: none;

        background-color: transparent;
    }
}