.eventsSection__main {
    height: fit-content;
    width : 100%;

    position: relative;
}

.eventsSection__container {
    position   : relative;
    padding-top: 80px;

    h1 {
        margin-bottom: 20px;
    }

    .row:nth-child(2) {
        margin-top: 100px;
    }

    .row:nth-child(3) {
        margin-top: 60px;
    }
}

.eventsSection__polygon-1-wrapper {
    
    height: fit-content;
    width: 100%;

    overflow: hidden;

    img {
        width: 100%;
        height: auto;
        border-radius: 10px;
    }
}

.eventsSection__welcome-row {
    img {
        height: auto;
        width: 70%;
        margin: 0px auto;
    }
}