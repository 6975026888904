.chatButton__wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;

    height: fit-content;
    width: fit-content;

    border-radius: 50%;
    border: 3px solid $theme-secondary-orange;

    background-color: transparent;

    z-index: 999;

    i {
        font-size: 50px;

        color: $theme-secondary-orange;
    }
}