.clientsSection__main {
    position: relative;

    background-size: cover;
    background-position: center;
    background-attachment: fixed;

    &::after {
        content: "";
        display: block;

        position: absolute;
        top: 0;
        left: 0;

        height: 100%;
        width: 100%;

        background-color: rgba($theme-dark-gray, 0.7);
    }
}

.clientsSection__wrapper {
    position: relative;
    z-index: 1;
    
    padding-top: 60px;

    h4 {
        margin-top: 800px;
    }
}

.clientsSection__decorator-bottom {

    margin-top: 50px;

    path {
        fill: $theme-tertiary-blue;
    }
}

.clientsSection__hexagon {
    width: 100%;

    background-color: #FFFFFF;
    clip-path: polygon(0 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);

    display: flex;
    justify-content: center;
    align-items: center;

    &.--fill {
        background-color: transparent;
    }

    img {
        height: auto;
        width: 90%;
    }
}

.clientsSection__hexagon-container {
    margin-top: 100px;

    @include screen-max(992px) {
        margin-top: 60px;
    }
}
